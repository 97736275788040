.report-history{
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  background-color: #fff;
  margin-bottom: 20px;
}
.report-history div{
  height: 45px;
  border: 1px solid #ccc;
  width: calc((100%) / 7);
  text-align: center;
  font-size: .8em;
}
.report-history div.done{
background-color: #bffeb8;
}