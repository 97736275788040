.show{
  display:block
}
.hide{
  display: none;
}
header{
  padding: 0;
}
header .logo {
  width: 400px;
  font-size: 20px;
  color:#fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}

header .logo div.version{
  font-size: .6em;
  margin-left: 10px;
  margin-top: 9px;
}

header .logaut p {
  color: #fff;
  margin-right: 10px;
  font-size: .9em;
}

header div.logaut {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}