div.return-comment{
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

div.return-comment h2{
  margin-bottom: 10px;
}

div.return-comment div.comment-item{
  display: flex;
  flex-direction: row;
}

div.return-comment div.comment-item div.date{
  width: 200px;
}

div.reload div.btn-box{
  font-size: 1em;

}
div.reload div.btn-box:hover{
 text-decoration: underline;

}