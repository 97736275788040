.memo-list{
  position: fixed;


  z-index: 100;
  top:60px;
  left:58px
}
.memo-window{
  position: absolute;
  background-color: #feeeb7;
  border: 1px solid #777;

  overflow:hidden;
  display: flex;
  flex-direction: column;
}
.memo-window textarea{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  padding: 5px;
  font-size: 1em;
  background-color: #feeeb7;
}
.memo-window div.header{
  width: 100%;
  background-color: #333;
  font-size: .6em;
  color:#fff;
  padding: 2px;
  display: flex;
  flex-direction: row;


}
.memo-list-new{
  color: #fff;
}

.delete{
  text-align: right;
  font-size: .8em;
  justify-content: flex-end;
  background-color: #000;
  padding: 2px 4px;
  color:#fff;
  margin-left: auto;
}
.memo-list-new{
  width: calc(100vw - 80px);
  text-align: right;
  margin-right: 10px;
  margin-top: 5px;
  font-size: .9em;
  color:#000
}