div.mpb {
  position: relative;
}

div.mpb div.logo{
  position: absolute;
  top:0;
  right: 0;
  width: 85px;
}
div.mpb div.logo img{
  width: 100%;
}